/* eslint-disable react/prop-types */
import Head from "next/head";
import { getDocumentByUid, renderSlices } from "helpers/Prismic";
import globalProps from "utils/globalProps";
import Script from "next/script";

export default function Home({ homepageContent }) {
  const {
    body,
    seo_title: seoTitle,
    seo_description: seoDescription,
  } = homepageContent || {};

  return (
    <div>
      <Head>
        <link rel='preconnect dns-prefetch' href='https://triplewhale-pixel.web.app/' crossOrigin="true" />
        <link rel='preconnect dns-prefetch' href='https://api.config-security.com/' crossOrigin="true" />
        <title>{seoTitle}</title>
        <meta name="description" content={seoDescription} />
      </Head>
      <Script src="tripleWhale.js" dangerouslySetInnerHTML={{ __html: 'TripleHeadless = "aftershockpcsg.myshopify.com"'}} strategy="lazyOnload" />

      <main>
        {body &&
          body.map((block, index) =>
            renderSlices(block, block.slice_type, index)
          )}
      </main>
    </div>
  );
}

export async function getStaticProps({ preview = false, previewData }) {
  const {
    props: { global },
  } = await globalProps();

  const { ref } = previewData || {};

  const homePage = await getDocumentByUid("landing-page", "homepage-new-my", ref);

  const homepageContent = homePage?.data;

  return {
    props: {
      global,
      homepageContent: homepageContent || null,
      preview,
    },
    revalidate: 1,
  };
}
